// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-js": () => import("./../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-copyright-js": () => import("./../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-blog-page-js": () => import("./../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-product-page-js": () => import("./../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

